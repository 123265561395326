<template>
    <div class="d-flex align-items-baseline" v-if="data.list.length">
        <nav
            aria-label="..."
            class="d-flex justify-content-center text-center mx-auto">
            <ul class="pagination my-0" :class="{ 'pagination-sm': small }">
                <li
                    :class="`page-item ${l.active ? 'active' : ''}  ${
                        l.label == '...' || l.active ? 'disabled' : ''
                    }`"
                    v-for="l in data.links"
                    :key="l.label">
                    <a
                        class="page-link"
                        href="#"
                        @click.prevent="emitPage(l.label)"
                        >{{
                            l.label.includes('previous') ||
                            l.label.includes('Previous') ||
                            l.label.includes('next') ||
                            l.label.includes('Next')
                                ? ''
                                : l.label
                        }}
                        <i
                            class="bx bx-caret-left"
                            v-if="
                                l.label.includes('previous') ||
                                l.label.includes('Previous')
                            "></i>
                        <i
                            class="bx bx-caret-right"
                            v-if="
                                l.label.includes('next') ||
                                l.label.includes('Next')
                            "></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import number from '../mixins/number';
export default {
    mixins: [number],
    props: {
        data: {
            type: Object,
        },
        small: {
            type: Boolean,
        },
    },
    computed: {
        prev_page() {
            return this.data.current_page - 1;
        },
        next_page() {
            return this.data.current_page + 1;
        },
    },
    methods: {
        emitPage(page) {
            if (page.includes('Previous') || page.includes('previous')) {
                if (this.data.current_page > 1) {
                    this.$emit('emitPage', this.data.current_page - 1);
                }
            } else if (page.includes('Next') || page.includes('next')) {
                if (this.data.current_page < this.data.last_page) {
                    this.$emit('emitPage', this.data.current_page + 1);
                }
            } else {
                this.$emit('emitPage', parseInt(page));
            }
        },
    },
};
</script>

<style scoped>
nav {
    margin-left: auto;
}
.pagination {
    display: flex;
    flex-wrap: wrap;
}

.page-item.active a {
    background: #2a3042;
    border-color: #2a3042;
}
.page-link:focus {
    box-shadow: 0 0 0 0.15rem rgb(11 14 30 / 25%) !important;
}
.page-link:hover {
    color: #2a3042;
}
</style>
